<template>
  <v-main align="center">
    <v-container class="fill-height" fluid v-if="hasCompany && !status.loading">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="10">
          <v-card class="elevation-12">
            <ApplicationToolbar :title="$tc('application.name', 2)" />
            <router-view></router-view>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import store from "@/stores/store";
import i18n from "@/languages";
import ApplicationToolbar from "@/components/application/ApplicationToolbar";

export default {
  name: "Application",
  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
  },
  methods: {},
  created() {
    if (!this.hasCompany) {
      this.$router.push("/company/create");
      store.dispatch(
        "alert/error",
        i18n.t("application.noCompanyError", { root: true })
      );
    }
  },
  components: {
    ApplicationToolbar,
  },
};
</script>

<style>
</style>